//引入 axios 模块
import axios from 'axios'
import Qs from 'qs'
import vueMain from '@/main.js'
import {
    MessageBox
  } from 'element-ui'
//判断环境配置

const baseUrl = process.env.NODE_ENV === 'development' ? '/api' : 'https://gxrcexam.nccloudmedia.com/index.php' //   /api本地开发环境下的接口地址   '' 里面填写上线之后的接口地址


axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// 创建一个axios实例

const intance = axios.create({
  baseURL: baseUrl,
  timeout: 50000, //请求响应时间

})


// 请求拦截
intance.interceptors.request.use(config => {
  // 在请求之前要做的事
  config.data = Qs.stringify(config.data)
  return config
}, error => {
  //请求拦截失败之后

  // console.log('请求拦截失败');
  return Promise.reject(error)
})


// 响应拦截

intance.interceptors.response.use(response => {
  // 响应数据要做的事

  // console.log('响应拦截成功');
  // console.log(response);
  return response.data
}, error => {
  // 响应失败之后要做的事

  // console.log('响应拦截失败');
  return Promise.reject(error)
})


// get请求
export function getRequest(url, data = {}) {
  return intance.get(url, {
    params: data
  })
}

// post请求
export function postRequest(url, data = {}, blon) {
  // 验证Token
  let blons = (blon == undefined ? true : blon) && true
  if (blons) {
    //获取localStroage值
    var token = localStorage.getItem("token")
    let obj = {
      token: token
    }
    return intance.post('/api/user/checkToken', obj).then(res => {
      if (res.code == 1) {
        data.token = obj.token;
        return intance.post(url, data)
      } else {
        MessageBox.confirm('您还没未登录或登录已过期~', '登录', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          localStorage.setItem('token', '')
          vueMain.$router.push('/signLogin');
        }).catch(() => {
          vueMain.$router.push('/index');
        });
      }
    })
  } else {
    // 不需要验证token
    return intance.post(url, data);
  }
}

/** 
 * 
 * 图片上传
 * **/

// formdata 请求
const upLoadImage = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

// 响应拦截
upLoadImage.interceptors.response.use(response => {
  // 响应数据要做的事

  // console.log('响应拦截成功');
  // console.log(response);
  return response.data
}, error => {
  // 响应失败之后要做的事
  // console.log('响应拦截失败');
  return Promise.reject(error)
})


export const upLoadRequest = (url, data = {}) => {
  return upLoadImage.post(url, data)
}
