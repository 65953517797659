import {
    postRequest,
    getRequest
} from "./request";
// 文章
export const articleLists = data => postRequest("/api/article/lists", data, false);
// 文章详情
export const articleDetail = data => postRequest("/api/article/detail", data, false);
// 推荐位列表
export const positionList = data => postRequest("/api/system_position.content/lists", data, false);
// 推荐位详情
export const positionDetail = data => postRequest("/api/system_position.content/detail", data, false);
// 登陆
export const userLogin = data => postRequest("/api/user/login", data, false);
// 注册
export const userRegister = data => postRequest("/api/user/register", data, false);
// 验证token
export const checkToken = data => postRequest("/api/user/checkToken", data, false);
// 注册短信接口
export const sendRegisterCode = data => postRequest("/api/sms/sendRegisterCode", data, false);
// 发送修改密码短信
export const sendEditPasswordCode = data => postRequest("/api/sms/sendEditPasswordCode", data, false);
// 获取图形验证码
export const uploadCode = data => postRequest("/api/common/captcha", data, false);
// 用户详情
export const userDetail = data => postRequest("/api/user/detail", data, true);
// 用户详情编辑
export const editInfo = data => postRequest("/api/user/editInfo", data, true);
// 修改密码
export const changePassword = data => postRequest("/api/user/changePassword", data, false);
// 考试列表
export const examLists = data => postRequest("/api/exam/lists", data, false);
// 考试列表详情
export const examListDetail = data => postRequest("/api/exam/detail", data, false);
// 分类
export const categoryLists = data => postRequest("/api/category/lists", data, false);
// 提交报名
export const applySubmit = data => postRequest("/api/exam.apply/submit", data, true);
// 报名列表
export const applyMyList = data => postRequest("/api/exam.apply/myList", data, true);
// 报名详情
export const applyDetail = data => postRequest("/api/exam.apply/detail", data, true);
// 岗位列表
export const getExamPostion = data => postRequest("/api/position/getExamPostion", data, false);
// 考试类型
export const commonConfig = data => postRequest("/api/common/config", data, false);
// 考试类型
export const applyEdit = data => postRequest("/api/exam.apply/edit", data, true);
// 准考证列表
export const registrationList = data => postRequest("/api/exam.apply.registration/lists", data, true);
// 准考证详情
export const registrationDetail = data => postRequest("/api/exam.apply.registration/detail", data, true);
// 成绩查询
export const queryScore = data => postRequest("/api/exam.apply.registration/queryScore", data, false);
// 支付
export const orderPay = data => postRequest("/api/exam.apply.order/pay", data, false);
// 支付详情
export const orderPayDetail = data => postRequest("/api/exam.apply.order/detail", data, false);
// 退款
export const orderRefund = data => postRequest("/api/exam.apply.order/refund", data, true);